.nav-link{
    background-color: #343a40;
}

.navbar{
    background-color: #343a40;
}

.floatDiv{
    position: fixed;
        top: 150px;
        float: left;
        left: 110px;
        width: 250px;
}

.widePiece{
    width: 100%;
}

.textBlack{
    color:black;
}
