.text-black{
    color: black;
}
.text-red{
    color: red;
}
.text-blue{
    color: blue;
}
.text-green{
    color: green;
}