.limeGreen {
    color: limegreen;
}

.bg-pink{
    background-color: pink;
}

.text-black{
    color: black;
}
  

 

 