.sign-in-container {

    background-color: white;
    color:black;
    //display: flex;
    //flex-direction: column;
    //width: 380px;

    h2 {
        margin-top: 10px 0;
    }

}

.buttons-container{
    display: flex;
    justify-content: space-between;
}
