.navigation {
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
  
    .logo-container {
      height: 75%;
      width: 75%;
      padding: 25px;

      .logo{
        width: 175px;
      }
    }
  
    .nav-links-container {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
  
      .nav-link {
        padding: 10px 15px;
        cursor: pointer;
        color:white;
        
        
      }

      .nav-link:hover{
        color: red;
      }
      
    }
  }
  

 

 